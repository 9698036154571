/*#chat-widget-container {
	display: none !important;
}
body:has(.cskh) #chat-widget-container {
	position: unset !important;
	display: block !important;
}
#chat-widget-container iframe {
	height: calc(100% - 125px) !important;
	top: 40px !important;
}
*/
#chat-widget-minimized, #chat-widget-container {
	width: 0!important;
	height:0 !important;
}
#chat-widget {
	position: fixed!important;
}
iframe {
	border: 0;
	width: 100%;
	height: calc(100vh - 130px);
}