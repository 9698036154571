.item_accountprofile {
	display: flex;
	margin-top: 25px;
	justify-content: space-around;
	background-color: white;
}
.account_profile {
	margin-top: 10px;
}
.titleitem {
	font-weight: 700;
	font-size: 18px;
	margin-bottom: 10px;
}
.edit_account_bankl {
	margin-top: 10px;
}

.detail_user {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 20px;
}
.username_ {
	font-weight: 600;
	padding: 10px;
}
.modal {
	display: none;
	animation: ModalGrow ease-in 0.2s;
}

.modalheader {
	display: flex;
	justify-content:center;
	padding-top: 30px;
	padding-bottom: 28px;
    font-weight: 600;
    font-size: 25px;
}
.modalheader h3 {
	font-size: 2rem;
	font-weight: 400;
	margin: 0;
}
.modalheader span {
	font-size: 1.5rem;
	line-height: 100%;
	margin: auto 0;
	cursor: pointer;
	color: var(--shop_be-color);
}

.modalformsocials a:hover,
.modalhelp a:hover,
.btn:hover,
.modalheader span:hover {
	opacity: 0.7;
}

.modalinner {
	padding: 30px;
	background-color: white;
	border-radius: 10px;
}
.item_btn_form {
	display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.modalforminput {
	width: 100%;
	height: 40px;
	display: block;
	margin-bottom: 28px;
	font-size: 1.4em;
	border: 1px solid #ccc;
	border-radius: 2px;
}

.btn {
	height: 40px;
	width: 100%;
	background-color: #efbf6e;
	color: var(--white-color);
	font-size: 1.4rem;
	border: 1px solid #ccc;
	border-radius: 2px;
	cursor: pointer;
}

.modalhelp {
	display: flex;
	justify-content: space-between;
	padding-top: 12px;
}
.modalhelp a {
	font-size: 1.3rem;
	text-decoration: none;
}

.modalformsocials {
	margin-bottom: 1rem;
	padding: 2rem 0;
	display: flex;
}
.modalformsocials a {
	flex: 1;
	text-align: center;
	text-decoration: none;
	font-size: 1.4rem;
	line-height: 40px;
	color: var(--white-color);
	border-radius: 4px;
}
.btn-with-facebook {
	background-color: #1877f2;
	margin-right: 1rem;
}
.btn-with-google {
	background-image: linear-gradient(
		to right,
		#ea4335,
		#fbbe0d,
		#8ccd9d,
		#4285f4
	);
}
.modal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	display: flex;
}

.modaloverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
}

.modalbody {
	background-color: var(--white-color);
	position: relative;
	margin: auto;
	border-radius: 5px;
	width: 400px;
}
.closelogin {
	color: white;
	position: absolute;
	font-size: 30px;
	right: 10px;
	top: 50px;
	font-weight: bold !important;
	cursor: pointer;
}
.closelogin:hover {
	color: rgb(239 191 110);
}
