.app123 {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 0 30px 30px;
	z-index: 2;
	position: relative;
    min-height: 100vh;
}
body:has(.app123) {
    background: #f8f3e9;
    background-size: cover;
    background-position: center;
}
.app123 form {
    background: #fff;
    border-radius: 10px;
    color: #333;
    flex: 1 1;
    margin: 1rem 0 3rem;
    padding: 20px;
    position: relative;
    width: 100%;
    border: 1px solid rgba(0,0,0,.125)
}
.app123 form h1 {
    margin: 1rem 0 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #ff7f27;
}
.header-lg .login_form {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.05333rem;
    margin-bottom: 30px;
}
.inputs > div {
    position: relative;
}
.inputs .change-visible {
    position: absolute;
    top: 53%;
    right: 10px;
    transform: translateY(-50%);
}
.inputs input {
    width: 100%;
    color: #333;
    margin: 10px auto;
    padding: 10px 15px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    outline: none !important;
    font-size: 16px;
    background: #fff;
	height: 38px;
}
.inputs input::placeholder {
    font-size: 16px;
    color: #555;
}
.app123 button {
    background: #0069d9;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    margin: 10px auto;
    padding: 12px 15px;
    position: relative;
    width: 100%;
}
.app123 p {
    color: red;
}
.app123 p:empty {
    display: none;
}
.app123 p.p-lg {
    text-align: left;
    margin: 0 0 10px;
}
.app123 a.a-lg {
    color: #007bff;
    text-decoration: none;
}
.header {
    background: #ff9521;
    padding: 10px;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    font-weight: bold;
    text-shadow: 0.3px 0.3px #000000;
}
.bg-bot {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	max-width: 540px;
	z-index: 0;
}
.bg-bot img {
	width: 100%;
	height: auto;
    filter: hue-rotate(225deg)
}
.logo-login img {
    margin-top: 30px;
    width: 90%;
}
.flag {
	position: absolute;
	left: 18px;
	height: 22px;
	top: 18px;
	overflow: hidden;
	display: flex;
	align-items: center;
	color: #333;
	font-size: 16px;
}
.flag img {
	height: 100%;
	width: auto;
	margin-right: 3px;
}