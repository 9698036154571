.addmnqrcode {
	display: flex;
	font-size: 1rem;
	background: white;
	/* align-content: center; */
	align-items: center;
    font-weight: 600;

}
.ipadd{
    padding: 8px;
    font-size: 15px;
    display:block;
    width:100%;
    margin:5px auto 15px;
    font-family: "Inter", sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}
.ipadd:focus {
    border: 1px solid #e2e5ec;
    outline: none;
}
label {
    font-weight:400
}
.btn-submit {
    margin-top:15px;
    border:0;
    background:#ff9521;
    color:#444!important;
    border-radius:5px;
    padding:10px 25px;
    font-size:16px;
}
.history_payment{
    display: flex;
    align-items: center;
    padding: 5px;
    background-color: white;
    justify-content: space-between;

}
.type_payment{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 10px;
}
.typepayment_detail{
   
    margin-left: 10px;
    font-size: 15px;
    font-weight: 600;
}
.date_time{
    
    margin-left: 10px;
    font-size: 12px;

}
.line-pay{
    border-width: 0.1px;
    padding: none;
    margin: 0;
    border-color: white;
}
.money_pamn{
    font-weight: 600;
    min-width:180px;
    text-align:right
}
.content_profile .history_payment + hr{display:none}
.content_profile .history_payment:has(img){display:flex}
.content_profile .history_payment:has(img) + hr{display:block}
.content_profile .history_payment{display:none}
