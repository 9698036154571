body {
  background:#f8f3e9;
  font-family: "Inter", sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 auto;
  max-width: 540px;
  position:relative;
  min-height:100vh;
  box-shadow:0 0 0.13333rem 0 rgba(125,124,124,.3)
}
body:has(.MuiContainer-root){
  max-width:100%;
  box-shadow:none;
  background:#fff!important;
}
.bg-fff{
  background:#fff;
  min-height:100vh;
}
a {
  color:#000
}
*{
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img {
  max-width:100%;
  height:auto;
}
input, select, textarea {
  border:1px solid #e2e5ec;
  border-radius:3px;
  padding:8px;
  width:100%;
  font-size:15px
}
html {
  background: #f2f2f2;
}
.modalformgroup.d-flex {
	display: flex;
	align-items: center;
	flex-flow: row wrap;
}
.modalformgroup.d-flex > div {
	flex: 1;
	text-align: left;
}
.modalformgroup.d-flex > div:first-child {
	flex: 0 0 120px;
	text-align: left;
}
.modalbody:has(.modalformgroup.d-flex) {
	width: 500px
}
select, textarea {
	border: 1px solid #e2e5ec!important;
	padding:5px;
	max-width: 100%;
}
.form-admin input, .form-admin textarea {
	width: 100%;
}
.modalformgroup input {
  height: 1rem;
  padding: 10px;
}
table tr td {
  border-bottom: 1px solid #eee!important;
}
.col-1-1 {
    float: left;
    width: 50%;
    max-width: 250px;
    padding: 5px;
}