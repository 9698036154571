.footer {
	position: fixed;
	left: 0;
	margin: auto;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: row;
	background: transparent;
	width: 100%;
	max-width: 540px;
	align-items: center;
	z-index: 9999;
}
.item-footer {
	padding: 0 0 6px 0;
	flex: 1;
}
.title_footer {
	width: 100%;
	color: #fff;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
}
.icon_footer {
	width: 100%;
	color: #fff;
	font-size: 0.8rem;
	font-weight: 500;
	text-align: center;
}
.icon_footer svg {
	fill: #fff;
	font-size: 1.3rem;
}
