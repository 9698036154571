input:hover,
input:focus,
input:active {
	outline: none;
	border-color: #678cf0;
}
.avatar_profile1 {
	display: flex;
	justify-content: center;
	padding: 10px;
	position: relative;
}
.logo {
	background: #ffb763;
	padding: 5px;
}
.logo img {
	height: 40px;
}
.bg-menu {
	background: linear-gradient(180deg,#ffb763,#ff9521);
	height: 90px;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 9999;
	max-width: 540px;
}
.detail_id {
	display: flex;
	/*background:#f0f0fa;
	color:#7f72a8;*/
	background: transparent;
	color: #333;
	position: fixed;
	bottom: 55px;
	padding: 10px 10px 5px;
	width: 100%;
	max-width: 540px;
	align-content: center;
	flex-wrap: wrap;
	justify-content: space-between;
	font-size: 13.5px;
	min-height: 38px;
	/*border-top:1px solid #ddd;*/
	z-index: 9999;
}
.item_id {
	word-wrap: break-word;
	white-space: normal;
	overflow: hidden;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	font-weight: 600;
}
.name_profile1 {
	display: flex;
	position: relative;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 1.5rem;
}
.choose {
	padding: 50px;
	background: white;
	position: absolute;
	border: none;
	color: #7d7c7c;
	font-weight: bolder;
	font-size: 20px;
	/* visibility: hidden; */
}
.isChoose {
	padding: 50px;
	background: red;
	position: absolute;
	border: none;
	color: white;
	font-weight: bolder;
	font-size: 20px;
}
.isActive {
	padding-bottom: 66%;
	position: relative;
	padding: 1.53333rem;
	color: white;
	background-color: red;
}

.record_bet {
	position: relative;
	width: 100%;
	z-index: 1;
}
.cycle_bet {
	display: flex;
	width: 100%;
	align-items: center;
	padding: 0 0 0.73333rem 0;
	justify-content: center;
}
.info_bet {
	margin-left: 0.26667rem;
	margin-right: 0.26667rem;
	height: 1rem;
	padding: 10px;
	line-height: 0.8rem;
	font-size: 1rem;
	color: #444;
}
.count {
	margin-left: 0.26667rem;
	margin-right: 0.26667rem;
	line-height: 1;
	height: 1rem;
	font-size: 1rem;
	color: #444;
}
.count svg {
	font-size: 1.3rem;
	position: relative;
	top: 5px;
	margin-right: 5px;
}
.wrapper_bet {
	position: absolute;

	overflow: hidden;
	display: flex;
}
.options_bar {
	display: flex;
	margin-top: 40px;
	/* position: absolute; */
	align-items: center;
	background-color: hsla(0, 0%, 97%, 0.979);
	height: calc(100% - 1.33333rem);
}

.item_bet {
	display: flex;
	padding: 0.26667rem 0;
	flex-direction: row;
	align-items: center;
	margin-top: 20px;
	justify-content: flex-start;
	flex-wrap: wrap;
}
.rectanger_bet {
	margin: 0 0 0.4rem 4%;
	width: 44%;
	border-radius: 0.13333rem;
}
.wraper_rectanger {
	padding-bottom: 66%;
	position: relative;
	padding: 1.53333rem;
	background: #fff;
}
.content_item_bet {
	display: flex;
	top: 0;
	left: 0;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}
.title_item_bet {
	font-size: 1.48rem;
	color: #fffdfd;
	font-weight: bolder;
}
.bet_user {
	position: fixed;
	left: 0;
	margin: auto;
	right: 0;
	max-width: 540px;
	bottom: 0;
	display: flex;
	box-shadow: 0 0 0.333333rem 0 #cacaca;
	flex-direction: row;

	z-index: 1;
	background-color: #fff;
	align-items: center;
	justify-content: space-around;
}
.title_money {
	font-size: 1.2rem;
	font-weight: 500;
}
.money_account {
	padding: 7px;
}
.title_money1 {
	font-size: 1.2rem;
	font-weight: 500;
	color: red;
}
.btn_betuser {
	padding: 10px;
	color: #fff;
	background: linear-gradient(270deg, #52b7d2, #6271ac);
	font-size: 1.42667rem;
	font-weight: 500;
	height: 3.51333rem;
	line-height: 1.01333rem;
	border-radius: 1.50667rem;
	outline: none;
	border: none;
}
.item_betuser {
	position: absolute;
	bottom: 4.33333rem;
	margin: auto;
	width: 470px;
	padding: 0 0.26667rem 1.6rem 0.26667rem;
	background-color: #fff;
	z-index: 1;
	box-shadow: 0 0 0.13333rem 0 #cacaca;
	transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);
}
@media only screen and (max-width: 390px) {
	.bet_user {
		position: fixed;
		width: 370px;
		bottom: 0;
		margin: 3px;
	}
	.item_betuser {
		bottom: 5.5rem;
		position: absolute;

		margin: auto;
		width: 470px;
		padding: 0 0.26667rem 1.6rem 0.26667rem;
		background-color: #fff;
		z-index: 1;
		box-shadow: 0 0 0.13333rem 0 #cacaca;
		transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);
	}
}
@media only screen and (max-width: 320px) {
	.item_betuser {
		bottom: -19.66667rem;
		position: absolute;

		margin: auto;
		width: 470px;
		padding: 0 0.26667rem 1.6rem 0.26667rem;
		background-color: #fff;
		z-index: 1;
		box-shadow: 0 0 0.13333rem 0 #cacaca;
		transition: transform 0.3s cubic-bezier(0.21, 1.02, 0.55, 1.01);
	}
	.bet_user {
		width: 370px;
	}
}

.list_choose {
	display: flex;
	font-size: 1.2rem;
	font-weight: 500;
}
.list_choose_item {
	margin-left: 10px;
	color: red;
}
.type_item2 {
	display: flex;
	color: white;
	font-size: 14px;
	font-weight: 400;
	margin: 0;
	padding: 0;
}
.trend__result-item2 {
	border: 0.5px solid #e2e5ec;
	line-height: 25px;
	padding: 8px 3px;
	text-align: center;
	text-transform: capitalize;
	font-weight: 500;
	font-size: 12px;
	display: flex;
	align-items: center;
	vertical-align: middle;
	color: #fff;
}
.trend__result-item {
	flex: 0 0 25%;
	width: 25%;
	border: 0.5px solid #e2e5ec;
	line-height: 25px;
	padding: 5px 3px;
	text-align: center;
	text-transform: capitalize;
	font-size: 13px;
}
.title-trend .trend__result-item,
.title-trend .trend__result-item2 {
	font-size: 14px;
	font-weight: 500;
	display: block;
	color: #fff;
}
.trend__result-item2:has(span) {
	padding: 0;
}
.trend__result-item2 span {
	flex: 1;
	margin: auto;
	border-right: 2px solid #e2e5ec;
	width: 100%;
	height: 100%;
	line-height: 40px;
	position: relative;
	color: #fff;
}
.trend__result-item2 span:last-child {
	border-right: 1px solid #e2e5ec;
}
.trend__result-item2 span:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #fff;
	color: #113b49;
	border: 1px solid #113b49;
	height: 20px;
	width: 20px;
	border-radius: 100%;
}
.trend__result-item2 span b {
	z-index: 2;
	color: #113b49;
	position: relative;
}
.item_history1 {
	display: flex;
	justify-content: space-around;
}
.total_bet {
	display: flex;
	font-size: 1.2rem;
	font-weight: 500;
}
.item_result {
	border: 1px solid #fff;
	font-weight: 600;
	height: 50px;
	font-size: 18px;
	margin: 0 8px;
	padding-bottom: 4px;
	color: #444;
	width: 50px;
	line-height: 48px;
	border-radius: 100%;
	background: #ff9521;
	border: 1px solid #daa0b4;
	position: relative;
}
.item_result:before {
	content: "";
	position: absolute;
	top: 1px;
	bottom: 1px;
	left: 1px;
	right: 1px;
	border-radius: 30px;
	border: 1px solid #fff;
}
.type_bet_item {
	color: #fff;
	background-color: #ff632c;
	border: 2px solid #fff;
	padding: 4px;
	border-radius: 3px;
	margin: 15px auto;
	padding: 5px 10px;
	font-size: 1.1rem;
}
.bet_item {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
}
.type_item3 {
	display: flex;
	color: #113b49;
}
.type_item2 {
	margin-top: 10px;
	background: #12999b;
	border-radius: 5px;
	padding: 10px;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
}
.bet__row_item {
	background: #333;
	border: 1px solid #ffffff;

	color: #fff;
	font-size: 14px;
	font-weight: 400;
	opacity: 1;
	padding: 12px 5px;
	text-transform: capitalize;
	width: 100%;
}
.row1 {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: center;
	justify-content: center;
}
.type_item {
	background-color: #113b49;
	display: flex;
	justify-content: space-around;
	font-size: 20px;
	margin-bottom: 5px;
	font-weight: 600;
}
.item_history {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	font-size: 20px;
	color: #fff;
	padding: 10px 0;
}
.item-dh {
	display: flex;
	width: 100%;
	padding: 0 15px;
	justify-content: space-between;
	font-size: 1rem;
}
.item-dh .result__head-item-logo-text {
	color: #fff;
	text-decoration: none;
	font-weight: bold;
}
.info-icon svg {
	font-size: 1.4rem;
	fill: #000;
	background: #fff;
	border-radius: 100%;
}
.item-dh-mid {
	color: #444;
	border: 0.5px solid #444;
	border-radius: 50px;
	padding: 3px 15px;
}
.history_mark {
	position: absolute;
	/* top: 357px; */
	/* left: 0; */
	/* right: 0; */
	margin-top: -6px;
	min-height: 500px;
	/* bottom: 0; */
	min-width: 486px;
	z-index: 3;
	background-color: rgba(121, 121, 121, 0.4);
	display: flex;
	align-items: center;
}
.popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10000;
}

.popup-content {
	background-color: white;
	width: 350px;
	padding: 20px;
	border-radius: 10px;
}
.title-nhapxuat {
	font-weight: 500;
	font-size: 1.3rem;
	margin-bottom: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
.btn-cancel {
	margin-top: 15px;
	border: 0;
	background: #ddd;
	color: #372f50 !important;
	border-radius: 5px;
	padding: 8px 25px;
	font-size: 14px;
	margin: 5px;
}
.list_money_bet input {
	border-radius: 30px;
	border: 1px solid #999;
	box-shadow: none;
	outline: none !important;
	font-size: 15px;
	padding: 10px 15px;
	margin: 10px auto 15px;
	width: 200px;
	text-align: center;
}
.bet {
	color: #fff;
	cursor: pointer;
	overflow-y: auto;
	padding: 15px;
	width: 100%;
	border-top: 1px solid #f3f3f3;
}
.bet__title {
	color: #555;
	margin: 0px auto 15px;
	padding: 5px 10px;
	font-size: 1.3rem;
	font-weight: 500;
	text-align: center;
}
.bet__items {
	float: left;
	width: 100%;
	text-align: center;
}
.bet__select .bet__items .bet__item {
	background: #ff9521;
	cursor: pointer;
	color: #444;
	padding: 15px 15px;
	font-size: 18px;
	border: 1px solid #daa0b4;
	border-radius: 30px;
	flex: 1;
	width: 40%;
	max-width: 200px;
	position: relative;
	display: inline-block;
	margin: 4%;
	font-weight: 500;
}
.bet__select .bet__items .bet__item:before {
	content: "";
	position: absolute;
	top: 1px;
	bottom: 1px;
	left: 1px;
	right: 1px;
	border-radius: 30px;
	border: 1px solid #fff;
}
.bet__select .bet__info {
	-webkit-box-flex: 0 !important;
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	-webkit-align-items: center !important;
	align-items: center !important;
	display: -webkit-box !important;
	display: -webkit-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-flex: 0 0 100% !important;
	-ms-flex: 0 0 100% !important;
	flex: 0 0 100% !important;
	-webkit-justify-content: center !important;
	justify-content: center !important;
	margin-bottom: 10px !important;
	width: 100% !important;
}
.bet__select .bet__info > div {
	-webkit-box-pack: center !important;
	-ms-flex-pack: center !important;
	-webkit-box-align: center !important;
	-ms-flex-align: center !important;
	-webkit-align-items: center !important;
	align-items: center !important;
	background: #fff !important;
	border: 1px solid #113b49 !important;
	-webkit-border-radius: 3px !important;
	border-radius: 3px !important;
	color: #113b49 !important;
	display: -webkit-box !important;
	display: -webkit-flex !important;
	display: -ms-flexbox !important;
	display: flex !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	-webkit-justify-content: center !important;
	justify-content: center !important;
	margin-right: 10px !important;
	opacity: 1 !important;
	padding: 5px !important;
	text-transform: capitalize !important;
	width: 100% !important;
}
.bet__select .bet__fake .bet__fake-item {
	-ms-flex-pack: justify;
	-ms-flex-item-align: stretch;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	-webkit-align-self: stretch;
	align-self: stretch;
	background: #fff;
	border: 1px solid #113b49;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	color: #113b49;
	-webkit-flex: 0 0 -webkit-calc(50% - 10px);
	-ms-flex: 0 0 calc(50% - 10px);
	flex: 0 0 calc(50% - 10px);
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 5px;
	padding: 7px 5px 7px 5px;
	position: relative;
	text-transform: capitalize;
	width: -webkit-calc(50% - 10px);
	width: calc(50% - 10px);
}
.bet__select .bet__fake,
.bet__select .bet__fake .bet__fake-item {
	-webkit-box-flex: 0;
	-webkit-box-pack: justify;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
}
.texthome {
	background: #113b49;
	border-radius: 3px;
	padding: 5px;
	color: #fff;
	font-size: 14px;
	line-height: 18px;
	margin: 0 15px 20px;
}
.popup-hd {
	position: absolute;
	max-width: 540px;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #fff;
	z-index: 99999999;
}
.popup-hd-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
	height: 50px;
	border-bottom: 1px solid #e2e5ec;
	background: #ff9521;
}
.popup-hd-header h3 {
	font-size: 16px;
	font-weight: 600;
	text-transform: uppercase;
	margin: 0;
}
.popup-hd-close {
	font-size: 25px;
	font-weight: 600;
	cursor: pointer;
}
.popup-hd-content {
	padding: 15px;
	font-size: 16px;
	line-height: 1.6;
	text-align: left;
}
.popup-hd-content p {
	margin: 0 0;
}
.popup-hd-content ul {
	padding-left: 20px;
	margin: 0;
}
.swal-button {
	background: red;
}
.swal-footer {
	text-align: center;
}
.swal-modal:has(.swal-icon--success) .swal-button {
	background: #a5dc86 !important;
}
.swal-modal:has(.swal-icon--error) .swal-button {
	background: #f27474 !important;
}
.swal-modal:has(.swal-icon--warning) .swal-button {
	background: #f8bb86 !important;
}
.swal-modal:has(.swal-icon--info) .swal-button {
	background: #3fc3ee !important;
}
.swal-footer {
	text-align: center;
}
.swal-icon img {
	max-width: 120px;
}
.tab-navigation {
	display: flex;
	padding: 0;
	margin-left: -10px;
	margin-right: -10px;
}
.tab-navigation li {
	list-style: none;
	color: #000;
	flex: 1;
	line-height: 40px;
	font-weight: 500;
	text-transform: uppercase;
	font-size: 15px;
	height: 35px;
	line-height: 35px;
	position: relative;
}
.tab-navigation li.active {
	background: #ff8da4;
	cursor: pointer;
	color: #555;
	padding: 0 15px;
	border: 1px solid #daa0b4;
	border-radius: 30px;
	margin: 0 5px;
	width: 100%;
	max-width: 150px;
	position: relative;
}
.tab-navigation li.active:before {
	content: "";
	position: absolute;
	top: 1px;
	bottom: 1px;
	left: 1px;
	right: 1px;
	border-radius: 30px;
	border: 1px solid #fff;
}
.bg-gift {
	background-image: url(../../img/bg_gift.png);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	min-height: 100%;
	max-height: 100vh;
	width: 100%;
	max-width: 540px;
	color: #fff;
	border-radius: 0px;
	overflow: hidden;
	padding: 85px 50px; 
	margin: 0;
}
.bg-gift .swal-text {
	color: #fff;
	font-weight: 500;
	text-align: center;
	font-size: 1rem;
	line-height: 1.4;
}
.bg-gift .swal-title {
	color: #d7d006;
	font-size: 1.4rem;
}
.bg-gift .swal-button {
	background: #ff9521;
	color: #444;
}
.box_15 {
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: -15px;
}
.gioi-thieu-bg {
	background: url(../../img/common_bg.png);
	background-size: cover;
	background-position: top center;
	background-repeat: no-repeat;
}
.gioi-thieu h2 {
	color: #f778a4;
	font-weight: normal;
	text-align: center;
	padding: 45px 20px 0;
	font-size: 24px;
}
.gioi-thieu-bg > div {
	padding: 20px;
	color: #666;
	text-align: left;
	font-size: 13px;
	letter-spacing: 0.1em;
	line-height: 1.6;
}
.gioi-thieu-bg > div > img {
	margin: 5px 0;
}
.gioi-thieu p {
	color: #333;
	line-height: 1.4;
	text-align: left;
}
.scroolbox {
	background: #fff;
	color: #333;
	padding: 10px;
}
.scroolbox h4 {
	margin-top: 1em;
	margin-bottom: 0;
}
.scroolbox p {
	margin-top: 0.3em;
}
.scroolbox {
	padding: 15px;
	overflow: hidden;
}
.scroolbox > div {
	list-style: none;
	margin-bottom: 25px;
}
.detail_id_home {
	background: linear-gradient(180deg,#ffb763,#ff9521);
	padding: 10px;
}
.lottery-header[data-v-69b3b4a1] {
	height: 50px;
	width: 100%;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	flex-wrap: nowrap;
	color: #000;
	position: relative;
	padding: 0 10px;
}
.lottery-header .back-btn[data-v-69b3b4a1] {
	width: 50px;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
}
.lottery .lottery-result .label,
.lottery .lottery-timer .label {
	flex-shrink: 0;
	font-weight: bold;
	font-size: 15px;
	line-height: 25px;
}
.lottery .lottery-result,
.lottery .lottery-timer {
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: stretch;
	align-items: stretch;
	flex-wrap: nowrap;
	padding: 10px;
	width: 100%;
	background-color: rgb(255, 255, 255);
	position: relative;
}
.lottery-timer[data-v-82095748] {
	width: 100%;
	height: 100%;
	background-color: transparent;
	font-size: 15px;
	display: flex;
	line-height: 25px;
	font-weight: bold;
	padding: 0px;
	z-index: 0;
	color: white !important;
	justify-content: space-between;
}
.lottery-timer .label[data-v-82095748] {
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	padding-left: 5px;
	margin-left: 30px;
}
.lottery-timer .content-box[data-v-82095748] {
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	align-items: center;
	background: transparent;
}
.lottery-timer .label .game-name[data-v-82095748] {
	font-size: 16px;
}
.lottery-timer .label .issue[data-v-82095748] {
	text-align: left;
	line-height: 18px;
	color: #fff;
	font-size: 18px;
}
.lottery-timer .content[data-v-82095748] {
	-webkit-box-flex: unset;
	flex: unset;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	padding-top: 5px;
}
.lottery-timer .content .time[data-v-82095748] {
	width: 40px;
	height: 35px;
	display: grid;
	place-items: center;
	margin-left: 0px;
	background: url(../../img/timer-bg.png) no-repeat;
	background-size: cover;
	border-radius: 5px;
	font-size: 16px;
	font-family: LCD;
	color: rgb(83, 93, 118) !important;
	line-height: 25px;
}
.lottery-result[data-v-4a0db828] {
	height: 70px;
	background: url(../../img/lottery-result-bg.png) 0% 0% / 100% 100% no-repeat;
	border-width: 0px 10px 10px;
	border-right-style: solid;
	border-bottom-style: solid;
	border-left-style: solid;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: transparent;
	border-image: initial;
	border-top-style: initial;
	border-top-color: initial;
	padding: 10px;
	display: flex;
	-webkit-box-pack: center;
	justify-content: center;
	position: relative;
	-webkit-box-align: stretch;
	align-items: stretch;
	flex-wrap: nowrap;
}
.lottery-result .label[data-v-4a0db828] {
	margin-left: 3%;
	margin-right: 3%;
}

.lottery-result .label[data-v-4a0db828] {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}
.lottery .lottery-result .label,
.lottery .lottery-timer .label {
	flex-shrink: 0;
	font-weight: bold;
	font-size: 15px;
	line-height: 25px;
}
.lottery-result .label .issue[data-v-4a0db828] {
	min-width: 80px;
	text-align: center;
	color: #333;
	font-weight: bold;
}
.lottery-result .content[data-v-4a0db828] {
	display: flex;
	-webkit-box-flex: 1;
	flex: 1 1 0%;
	text-align: left;
}
.lottery-result .content .list[data-v-4a0db828] {
	list-style: none;
	display: flex;
	flex-wrap: nowrap;
	-webkit-box-pack: start;
	justify-content: start;
	-webkit-box-align: center;
	align-items: center;
	margin-left: auto;
	margin-right: 10px;
	padding-left: 0;
}
.lottery-result .content .list .num-item[data-v-4a0db828] {
	width: 35px;
	height: 35px;
	line-height: 20px;
	font-size: 16px;
	color: #000;
	margin-right: 10px;
}
.numberlist {
	border: 1px solid #ccc;
	border-radius: 50%;
	padding: 3px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-weight: bold;
}
.lottery-timer .label[data-v-82095748]::before {
	content: "";
	display: block;
	height: 12px;
	width: 12px;
	position: absolute;
	left: 15px;
	top: 50%;
	border-width: 2px 0px 0px 2px;
	border-top-style: solid;
	border-left-style: solid;
	border-top-color: white;
	border-left-color: white;
	border-image: initial;
	border-right-style: initial;
	border-right-color: initial;
	border-bottom-style: initial;
	border-bottom-color: initial;
	transform: translate(0px, -50%) rotate(-45deg);
}
.lottery-code-panel-body {
	width: 100%;
	display: flex;
	-webkit-box-pack: start;
	justify-content: center;
	-webkit-box-align: start;
	align-items: start;
	flex-wrap: wrap;
	gap: 10px;
	padding: 0 10px;
}
.ybcp-num-item {
	width: calc(50% - 5px);
	list-style: none;
}
.lottery-code-item-name[data-v-0f9d40fc] {
	padding: 15px 0px;
	height: auto;
	min-width: 80%;
	width: auto;
	color: #fff;
	font-size: 16px;
	border-radius: 5px;
	font-weight: normal;
	display: block;
	margin: 0;
	background: #007bff;
	cursor: pointer;
	opacity: 0.8;
}
.lottery-code-item-odds[data-v-0f9d40fc] {
	background: #28a745;
}
.lottery-code-item {
	width: 33.33%;
	list-style: none;
}
.lottery-code-item > .lottery-code-item-name[data-v-0f9d40fc] {
	background: rgb(254, 244, 245);
	border: 1px solid rgba(205, 101, 98, 0.5);
	margin: 5px;
	padding: 5px;
}
.lottery-game-tab {
	width: 100%;
	min-height: 40px;
	box-sizing: border-box;
	margin-top: 10px;
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}
.lottery-game-tab .tab-item[data-v-260e3e17] {
	font-size: 15px;
	font-weight: bold;
	line-height: 30px;
	width: 110px;
	border-radius: 5px;
	border: 1px solid #ccc;
	background-color: transparent;
	cursor: pointer;
}
.lottery-game-tab .tab-item-active[data-v-260e3e17] {
	background: #000;
	color: #fff;
	border: 1px solid #000;
}
.lottery-code-item-name[data-v-0f9d40fc].active {
	box-shadow: 0 0 0 .2rem rgba(38,143,255,.5);
	opacity: 1;
}
.lottery-code-item-odds[data-v-0f9d40fc].active {
	box-shadow: 0 0 0 .2rem rgba(72,180,97,.5);
	opacity: 1;
}
.btn-close-bet {
	background: red;
	cursor: pointer;
	color: #eee;
    font-size: 15px;
    line-height: 35px;
    padding: 0px 12px;
    border-radius: 5px;
    border: 0;
	width: 90px;
}
.lottery-bet-bg {
	content: "";
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 99998;
	background: rgba(0,0,0,0.5);
	display: flex;
}
.lottery-bet-input {
	padding: 20px 15px;
	margin: auto;
	width: 90%;
	max-width: 500px;
	border-top: 1px solid rgb(239, 239, 239);
	/*box-shadow: rgba(124, 124, 124, 0.5) 0px 2px 8px 0px;*/
	z-index: 99999;
	background: #fff;
	border-radius: 10px;
}
.chip-row,
.bet-input {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.chip-row .chip {
	background-image: linear-gradient(
		rgb(255, 255, 255),
		rgb(247, 248, 253) 19%,
		rgb(252, 253, 255) 69%,
		rgb(252, 253, 255) 100%
	);
	border: 1px solid rgba(201, 202, 228, 0.57) !important;
	color: rgb(83, 93, 118) !important;
	padding: 5px 8px;
	width: 60px;
	font-size: 13px;
	border-radius: 5px;
	cursor: pointer;
}
.chip-row .chip.active {
	background: rgb(103, 140, 240) !important;
	color: #fff !important;
	border: none !important;
}
.lottery-bet-input button[type="submit"] {
	flex-shrink: 0;
	color: #fff;
	font-size: 15px;
	line-height: 35px;
	padding: 0px 12px;
	border-radius: 5px;
	border: 0;
	background-color: rgb(77, 143, 250) !important;
	background: url(../../img/game-tab-btn-bg.png) 0% 0% / cover no-repeat;
	width: 90px;
}
.lottery-bet-input input {
	width: 120px;
	height: 35px;
	font-size: 15px;
	line-height: 35px;
	padding: 5px 10px;
	border-radius: 5px;
	box-sizing: border-box;
	color: rgb(50, 50, 50);
	background: linear-gradient(
			rgb(251, 252, 253),
			rgb(241, 243, 253) 60%,
			rgb(251, 252, 253)
		)
		transparent;
	border: 1px solid rgba(201, 202, 228, 0.58);
	margin-right: 15px;
	margin-left: 15px;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	-webkit-box-pack: center;
	justify-content: center;
}
.lottery-bet-input input:focus,
.lottery-bet-input input:hover {
	border-color: #678cf0;
}
.bet-input {
	flex-shrink: 0;
	color: rgb(85, 85, 85);
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	font-size: 14px;
	margin-top: 20px;
}
.result_last ul {
	margin: 0;
	padding: 10px 0;
	border-bottom: 1px solid #fff;
}
.result_last li {
	width: 40px;
	height: 40px;
	line-height: 32px;
	font-size: 20px;
	color: #333;
	background: #fff;
	border-radius: 100%;
	display: inline-block;
	margin: 0 5px;
}
.text1 {
    font-size: 16px;
	text-align: left;
}
@media (max-width: 500px) {
    .text1 {
        font-size: 14px;
    }
}
.grid-6 {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	padding: 10px;
	text-align: center;
}
.grid-6 img {
	height: 100px;
	width: auto;
}
.grid-6 span {
	display: block;
	text-align: center;
}
.button-demo {
	display: flex;
	padding: 5px;
}
.button-demo > div {
	width: 20%;
	padding: 0 5px;
}
.button-demo button {
	align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #000;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
	padding: 5px 10px;
}
.button-demo button:focus, .button-demo button:hover, .button-demo button:active {
	background: #ffb763;
	box-shadow: 0 0 0 .1rem rgb(255 183 99 / 50%);
	color: #fff;
}