.info_profile {
	background: #ff9521;
	display: flex;
	justify-content: space-between;
	padding: 0 5px;
	border-bottom: 5px solid transparent;
}
.icon_setting_header {
	display: flex;
	align-items: center;
	color: #fff;
	font-size: 25px;
	padding-bottom: 5px;
	text-decoration: none;
}
.icon_setting_header > * {
	margin-right: 5px;
}
.header_profile {
	width: 100%;
	background-image: linear-gradient(270deg, #52b7d2, #6271ac);
}
.avatar_profile {
	display: flex;
	align-items: center;
}
.avatar_profile img {
	max-height: 58px;
}
.detail_avatar {
	overflow: hidden;
	border-radius: 50px;
	width: 15%;
	height: 15%;
	padding: 20px;
}
.name_profile {
	display: flex;
	align-items: center;
	color: white;
	font-weight: 500;
	font-size: 1.2rem;
}
.content_profile {
	position: relative;
}
/*
.finance_user{
    justify-content: space-around;
    position: absolute;
    display: flex;
    align-items: center;
    top: -20px;
    width:96%;
    left:2%;
    height: 3.33333rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.4rem;
}
.finance_item{
    flex: 1 1 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.finance_item svg {
    padding:0
}
.finance_item span {
    margin-left:10px
}
.line{
    width: 0.02667rem;
    height: 1.53333rem;
    background-color: rgb(204, 204, 204);
}
.wallet_user{
    margin-top: 4rem;
    top: -0.66667rem;
    left: 0.4rem;
    right: 0.4rem;
    width: 96%;
    padding: 0px 0.4rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.4rem;
    box-shadow: rgb(228, 228, 231) 0px 0.08rem 0.13333rem 0px;
    position: absolute;
}
.my_wallet{
    padding: 20px;
    font-size: 1.2rem;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    align-items: center;
    height: 1.2rem;
    border-width: 4px;
    justify-content: space-between;
}
.my_wallet:last-child{
    margin:10px 0
}
.border_wallet{
    width: 100%;
    height: 0.2px;
    background-color: rgb(242, 241, 241);
}
.balance_money{
    font-size: 2rem;
    color: rgb(98, 113, 172);
    font-weight: 700;
}
.balance_money_title{
    display: flex;
    align-items: center;
    color: #868686;
    font-size: 1.2rem;
}
.refesh_balance{
    background-color: rgb(121, 182, 225);
    margin: 4px;
    display: flex;
    align-items: center;
    padding:3px;
    border-radius: 15px;
    border: none;
}
.bonus_balance_money{
    font-size: 1.5rem;
    display: flex;
    color: rgb(98, 113, 172);
    font-weight: 700;
}
.bonus_balance_title{
    color: #868686;
    font-size: 1rem;}

.function_user{
    margin-top: 315px;
    position: absolute;
    top: -0.66667rem;
    width: 96%;
    left: 0.4rem;
    right: 0.4rem;
    padding: 0px 0.4rem;
    background-color: rgb(255, 255, 255);
    border-radius: 0.4rem;
    box-shadow: rgb(228, 228, 231) 0px 0.08rem 0.13333rem 0px;
}
.row_function_user{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;
    justify-content: space-between;
    align-items: center;
}
.row_1{
    display: flex;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
}*/
.list-bank {
	padding: 0 10px;
}
.list-bank > .title {
	font-weight: 500;
}
.list-bank a {
	text-decoration: none;
}
.item-bank {
	display: flex;
}
.item-bank > div {
	border: 0.5px solid #ddd;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.item-bank > div:not(.edit) {
	width: 30%;
	text-align: left;
	padding: 5px;
}
.item-bank > .edit img {
	margin: 5px auto;
}
.item-bank > .edit {
	width: 10%;
	text-align: center;
}
:root {
	--color-main: #25cb83;
	--color-main-hover: #25cb83;
	--color-text: #333;
	--color-text-light: #fff;
	--color-link: #1d2327;
	--color-link-hover: #242f48;
	--color-link-content: #6422c3;
	--color-link-content-hover: #0d6efd;
	--color-line: #e2e5ec;
	--color-note: #888c91;
	--color-section: #fff;
	--color-background: #f5f5f5;
	--box-shadow: 0px 4px 10px #626262;
	--border-input: 1px solid #fff;
	--transition: 300ms all ease-in-out;
	--scrollbarBG: #e9ecef;
	--width-content: 800px;
	--bg-main: #25cb83;
	--bg-app: #fff;
}
.account {
	color: var(--color-text);
	height: 100%;
	min-height: -webkit-calc(100vh - 85px);
	min-height: calc(100vh - 85px);
	width: 100%;
}
.account__top {
	background: linear-gradient(180deg,#ffb763,#ff9521);
	background-size: 100%;
	-webkit-border-bottom-left-radius: 30px;
	border-bottom-left-radius: 30px;
	-webkit-border-bottom-right-radius: 30px;
	border-bottom-right-radius: 30px;
	overflow: hidden;
	padding: 0 22px;
}
.account__top .account__heading {
	color: #444;
	font-size: 15px;
	font-weight: 600;
	line-height: 30px;
	margin-bottom: 15px;
	padding-top: 15px;
	text-align: center;
	text-transform: capitalize;
}
.account__top .account__ID {
	-webkit-box-pack: start;
	-ms-flex-pack: start;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	color: #444;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: flex-start;
	justify-content: flex-start;
}
.account__top .account__ID span {
	color: #444;
	margin: 0 5px;
}
.account__top .account__balance {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: center;
	justify-content: center;
	padding: 15px 0 50px;
}
.account__top .account__balance span {
	color: #444;
	display: block;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 15px;
}
.account__top .account__balance strong {
	color: #444;
	font-size: 32px;
	font-weight: 600;
}
.account__transaction {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
}
.account__transaction,
.account__transaction .account__transaction-box {
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}
.account__transaction .account__transaction-box {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	background: #ff7f27;
	border: var(--border-input);
	-webkit-border-radius: 50px;
	border-radius: 50px;
	color: var(--color-text-light);
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	margin-top: -36px;
	padding: 10px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	font-weight: 500;
}
.account__transaction .account__transaction-box .account__transaction-item {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-align-items: center;
	align-items: center;
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-justify-content: center;
	justify-content: center;
	padding: 0 35px;
}
.account__transaction .account__transaction-box .account__transaction-item svg {
	fill: #fff;
	display: block;
	font-size: 22px;
}
.account__transaction
	.account__transaction-box
	.account__transaction-item
	span {
	color: #fff;
	display: block;
	font-size: 14px;
	line-height: 15px;
	margin-top: 8px;
}
.account__transaction .account__transaction-box .account__transaction-line {
	background: var(--color-text-light);
	-webkit-border-radius: 100%;
	border-radius: 100%;
	height: 28px;
	width: 1px;
}
.account__menu {
	padding: 20px 22px 15px;
}
.account__menu .account__menu-item {
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	border-bottom: var(--border-input);
	cursor: pointer;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	font-size: 16px;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	padding: 8px 0 13px;
	color: #444;
}
.account__menu .account__menu-item i,
.account__menu .account__menu-item span {
	font-size: 17px;
}
.account__menu .account__menu-item span svg {
	margin-right: 10px;
	position: relative;
	top: 6px;
	width: 25px;
}
.account__menu .account__menu-item:last-child {
	border-bottom: none;
}
.account a {
	display: block;
	text-decoration: none;
}
.account__menu .account__menu-item > svg {
	font-size: 1.4rem !important;
	position: relative;
	top: 4px;
}
